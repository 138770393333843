<template>
  <div>
    <!--Header-->
    <section class="bg-gray-50">
      <div class="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 lg:px-8 xl:max-w-7xl">
        <div class="space-y-10 sm:space-y-12 lg:space-y-20 xl:space-y-24">
          <div class="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto" v-html="page.header"></div>
        </div>
      </div>
    </section>

    <!--Body-->
    <section class="bg-white">
      <div class="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 lg:px-8 xl:max-w-7xl">
        <div class="space-y-10 sm:space-y-12 lg:space-y-20 xl:space-y-24">
          <div class="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto" v-html="page.body"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "Page",

    data() {
      return {
        page: null,
      }
    },

    computed: {
      slug() {
        return this.$route.params.pageSlug
      },
    },

    methods: {
      fetchPage() {
        this.$store.dispatch('fetchPage', {
          slug: this.slug
        }).then(data => {
          this.page = data.data
        }).catch(error => {
          if (error.response && error.response.status == '404') {
            this.$router.push({name: 'NotFound'})
          }
        })
      }
    },

    created() {
      this.fetchPage()
    }
  };
</script>
